body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: env(safe-area-inset-top);
  margin-bottom: calc(4px +env(safe-area-inset-bottom));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  /* White background */
  /* background: #00000080; */
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone {
  width: 100%;
  height: 20%;
  /* border : 1px dotted rgb(100, 100, 100); */
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

h1 {
  margin: 0;
}

h2 {
  margin-top: 10px;
}

img {
  width: 100%;
  height: auto;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9e3;
  border-radius: 5px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.12);
} */

.markdown-body .table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.markdown-body table tr {
  background-color: transparent;
  border-top: 1px solid #d8dee4;
}

.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}

.markdown-body li p {
  display: contents;
}

.cursorP {
  cursor: pointer;
}

.MuiSvgIcon-root {
  cursor: pointer;
}

.ripple-container {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ripple {
  position: absolute;
  border: 4px solid #3f51b5;
  /* Ripple color */
  border-radius: 50%;
  opacity: 0.25;
  width: 100px;
  height: 100px;
  background-color: dodgerblue;
}

.ripple.animate {
  animation: ripple-animation 1.5s infinite ease-in-out;
}

.ripple:nth-child(2) {
  animation-delay: 0.5s;
}

.ripple:nth-child(3) {
  animation-delay: 1s;
}

.css-pyt3b0-MuiButton-endIcon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}


@keyframes ripple-animation {
  0% {
    transform: scale(0.7);
    opacity: 0.25;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse-text {
  0% {
    color: rgba(0, 0, 0, 0.7);
  }

  50% {
    color: rgba(0, 0, 0, 1);
  }

  100% {
    color: rgba(0, 0, 0, 0.7);
  }
}

/* 
.css-cyqh1t-MuiPaper-root-MuiDrawer-paper {
transition: transform 0.5s cubic-bezier(0, 0.3, 0.7, 1) !important;
  } */

#answer {
  width: 100%;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  #answer {
    max-width: 450px !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 435px) {
  #answer {
    max-width: 375px !important;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.css-cyqh1t-MuiPaper-root-MuiDrawer-paper {
  transition: transform 0.5s ease-in-out !important;
}

.addnewchat .css-16pv1fu {
  margin-left: 0px;
  margin-right: 0px;
}